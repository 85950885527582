.Directions-page {
    background-image: url("../../assets/images/long_road.jpg");
    background-color: white;
    background-position: center;
    background-size: cover;
    padding: 6em 0;
    color: white;
}
.About-text {
    font-size: 1.25em;
    background-color: rgba(0, 0, 0, 0.33333);
    padding: 1em;
    color: white;
    border-radius: 0.25em;
}
