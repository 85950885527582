.List-bullet-point {
    display: flex;
    gap: 2em;
    align-items: baseline;
    margin: 0 3.5em;
}

@media screen and (max-width: 980px) {
    .List-bullet-point {
        display: flex;
        gap: 1em;
        align-items: baseline;
        margin: 0 0.5em;
    }
}
