.Gallery-container {
    margin-top: 5em;
}
.Gallery-title {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 2em;
}
.Gallery-images {
    display: flex;
    gap: 0.25em;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1300px;
    margin: 0 auto 2em;
}
.Gallery-image {
    height: auto;
    max-width: 900px;
    width: 600px;
}
.Gallery-image-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
    padding-bottom: 1em;
}
.Gallery-image-group-btn {
    padding: 1em;
    border: 2px solid #774246;
    cursor: pointer;
    border-radius: 0.25em;
}
.Gallery-image-group-selected {
    color: white;
    background-color: rgba(119, 66, 70, 0.5);
}

@media screen and (max-width: 980px){
    .Gallery-container {
        margin-top: 2em;
    }
    .Gallery-image {
        height: auto;
        width: 100%;
        padding: 0 0.25em;
    }
}
