.Header-nav-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    align-items: center;
    gap: 1em;
    height: 10em;
    border-bottom: 3px solid rgba(119, 66, 70, 0.2);
}
.Nav-link {
    width: fit-content;
    text-decoration: none;
    color: #774246;
    font-size: larger;
    font-weight: bolder;
    cursor: pointer;
    text-wrap: nowrap;
    padding: 0.5em;
    border-radius: 0.25em;
}
.Nav-link-active {
    background-color: #77424626;
}
.Nav-link-logo {
    width: fit-content;
    /* Remove this */
    background-color: white;
    border-radius: 0 0 2em 2em;
    border: 3px solid rgba(119, 66, 70, 0.2);
    z-index: 1;
    box-shadow: 0px -2px 8px -1px black;
}
.Logo {
    margin-top: 1em;
    height: 80px;
    padding: 1em 1.5em 1em;
}

.Mobile-menu {
    visibility: collapse;
    height: 0;
    width: 0;
}
.Mobile-menu-logo-container {
    margin: auto;
    width: fit-content;
}
.Mobile-menu-logo {
    height: 50px;
}
.Mobile-menu-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    background-color: antiquewhite;
}
.Mobile-menu-bar {
    display: flex;
    flex-direction: row;
    padding: 0 1em;
    align-items: center;
}
.Mobile-menu-icons {
    font-size: 2em;
    color: #774246;
    cursor: pointer;
}
/* screen width max 980px*/
@media screen and (max-width: 980px) {
    .Mobile-menu {
        visibility: visible;
        height: fit-content;
        width: 100%;
    }
    .Header-nav-container {
        visibility: collapse;
        height: 0;
        width: 0;
    }
    .Nav-link {
        padding: 0.5em 0;
    }
}
