.Home-container {
    height: 100%;
}
.Home-hero-img {
    width: 100vw;
}
.Home-hero-title {
    z-index: 1;
    position: relative;
    color: #fff;
    text-align: center;
}

.Home-hero {
    text-align: center;
    color: white;
    background-image: url("../../assets/images/hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 4.5em;
    height: 60vh;
}
.Home-hero-sub-text {
    margin: 5em auto;
    padding: 0 1em;
    max-width: 980px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.Home-about {
    font-size: 1.25em;
}
.Home-hero-text {
    background-color: #00000057;
    padding: 1em;
    height: fit-content;
}
.Home-hero-text-light {
    font-weight: 100;
}
