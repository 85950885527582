.Contact-page {
    height: 100%;
    background-image: url("../../assets/images/mountain.jpg");
    background-position: center;
    background-size: cover;
    padding: 5em 0;
}
.Contact-container {
    background-color: rgba(0, 0, 0, 0.25);
    max-width: 980px;
    margin: auto;
    border-radius: 0.25em;
}
.Contact-text {
    color: white;
    margin: 0 auto 2em;
    padding: 3em 0 1em;
    max-width: 980px;
}
.Contact-iframe {
    width: 100%;
    border: 0;
    height: 28em;
}
.Contact-text-title {
    text-align: center;
    padding: 0 1em;
    font-size: 1.5em;
}
.Contact-text-body {
    text-align: center;
    padding: 0 1em;
    font-size: 1.25em;
    margin-top: 1em;
}
