.Image-background {
    position: fixed;
    top: 0;
    left: 0;
    scroll-behavior: unset;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
}
.Image-selected {
    background-position: center;
    background-size: contain;
    position: fixed;
    height: 80vh;
    width: 80vw;
    top: 10vh;
    left: 10vw;
    background-repeat: no-repeat;
}
.Left-img {
    background-position: right;
    background-size: cover;
    height: 30em;
    width: 18em;
    border-bottom-left-radius: 1.5em;
    border-top-left-radius: 1.5em;
}
.Top-right-img {
    background-position: center;
    background-size: cover;
    height: 14.90em;
    width: 16em;
    border-top-right-radius: 1.5em;
}
.Bottom-right-img {
    background-position: center;
    background-size: cover;
    height: 14.90em;
    width: 16em;
    border-bottom-right-radius: 1.5em;
}
.Kitchen:hover {
    animation: highlight 0.5s forwards;
    cursor: pointer;
}
.Front-door:hover {
    animation: highlight 0.5s forwards;
    cursor: pointer;
}
.Hover:hover {
    opacity: 0.7;
    cursor: pointer;
    animation: highlight 0.5s forwards;
}
@keyframes highlight {
    from { opacity: 1; }
    to { opacity: 0.7; }
}

/* Images */
.Kitchen-img {
    background-image: url("../../assets/images/rentals/36-ft-Keystone-Big-Sky-5th-Wheel/kitchen.png");
    cursor: pointer;
}
.Front-door-img {
    background-image: url("../../assets/images/rentals/36-ft-Keystone-Big-Sky-5th-Wheel/front-door-entry-inside.png");
    cursor: pointer;
}
.Living-room-img {
    background-image: url("../../assets/images/rentals/36-ft-Keystone-Big-Sky-5th-Wheel/livingroom.png");
    cursor: pointer;
}
.Outside-unit-img {
    background-image: url("../../assets/images/rentals/28-ft-Sportsman-5th-Wheel/outside-of-unit.jpg");
    cursor: pointer;
}
.Outside-area-img {
    background-image: url("../../assets/images/rentals/28-ft-Sportsman-5th-Wheel/outside-area.jpg");
    cursor: pointer;
}
.Diagram-img {
    background-image: url("../../assets/images/rentals/28-ft-Sportsman-5th-Wheel/diagram.png");
    cursor: pointer;
}
