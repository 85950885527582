.About-page {
    background-image: url("../../assets/images/long_road.jpg");
    background-color: white;
    background-position: center;
    background-size: cover;
    padding: 6em 0;
}
.About-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-width: 980px;
    gap: 3em;

}
.About-text {
    font-size: 1.25em;
    background-color: rgba(0, 0, 0, 0.33333);
    padding: 1em;
    color: white;
    border-radius: 0.25em;
}
.About-map {
    display: flex;
    justify-content: center;
}
.About-map-iframe {
    width: 980px;
    height: 50vh;
    border-radius: 0.25em;
}
.About-leave-review {
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: center;
    font-size: 1.25em;
}
h3 {
    padding: 1em;
    text-align: center;
}
