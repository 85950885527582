.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.Body-and-footer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.App-router-output {
    margin: 0;
}

@media screen and (max-width: 900px) {
    .App-router-output {
        margin: 0;
    }
}
