/*.Footer {*/
/*    width: 100%;*/
/*    background-color: #ddd;*/
/*}*/
/*.Footer-title {*/
/*    width: 100%;*/
/*    background-color: #ddd;*/
/*}*/
/*.Footer-content {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    padding: 3em 0;*/
/*    width: 100%;*/
/*    margin: auto;*/
/*    background-color: #ddd;*/
/*    justify-content: center;*/
/*    gap: 5em;*/
/*    flex-wrap: wrap;*/
/*}*/
.Join-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Join-email-txt {
    margin: 0 auto;
    text-align: center;
    padding-bottom: 1em;
    font-weight: 400;
}
.Iframe {
    width: 320px;
    overflow: hidden;
    scrollbar-width: none;
    border: 0;
    height: 100px;
}
/*.Footer-contact-info {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    gap: 2em;*/
/*    text-align: center;*/
/*}*/
/*.Address-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    gap: 1em;*/
/*}*/
/*.Address {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*}*/
/*.Flex-column {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*}*/
.Phone-link {
    text-decoration: none;
    color: black;
}
.Footer-background {
    /*background-color: #ddd;*/
    background-color: #432628;
    display: flex;
    justify-content: center;
    color: #ffffffdd;
}
.Footer {
    display: flex;
    flex-direction: column;
    /*background-color: #ddd;*/
    justify-content: space-between;
    padding: 3em 3em;
    width: 1190px;
}
.Footer-title {
    border-bottom: 1px solid #888;
    padding: 1em 0;
    margin-bottom: 2em;
    font-weight: 500;
}
.Footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1em;
}
.Address-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.Phone-button {
    border-radius: 0.25em;
    padding: 0.25em;
    margin-top: 0.5em;
    border: 1px solid #774246;
}
.Home-flex-paragraph {
    display: grid;
    /*grid-template-columns: 1fr 1fr;*/
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    align-items: flex-start;
    gap: 2em;
}
.Tag-line {
    margin-top: 3em;
    display: flex;
    justify-content: center;
}
